define("discourse/plugins/discourse-customer-flair-plugin/discourse/raw-views/slo-object", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SloObject extends _object.default {
    SLO_APPROACHING = "slo_approaching";
    SLO_PASSED = "slo_passed";
    get sloTime() {
      const value = this.get("topic.slo_time");
      if (!value) {
        return null;
      }
      return moment(value);
    }
    static #_ = (() => dt7948.n(this.prototype, "sloTime", [(0, _object.computed)("topic.slo_time")]))();
    get sloStatus() {
      const sloTime = this.get("sloTime");
      if (!sloTime) {
        return null;
      }
      if (sloTime.isBefore(moment())) {
        return this.SLO_PASSED;
      } else if (sloTime.isBefore(moment().add(1, "hour"))) {
        return this.SLO_APPROACHING;
      }
      return null;
    }
    static #_2 = (() => dt7948.n(this.prototype, "sloStatus", [(0, _object.computed)("sloTime")]))();
  }
  _exports.default = SloObject;
});