define("discourse/plugins/discourse-customer-flair-plugin/discourse/raw-views/whisper-icon", ["exports", "@ember/object", "discourse-common/lib/icon-library", "discourse/plugins/discourse-customer-flair-plugin/discourse/raw-views/slo-object"], function (_exports, _object, _iconLibrary, _sloObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WhisperIcon extends _sloObject.default {
    get whisperIconHTML() {
      if (!this.sloStatus) {
        return null;
      }
      const lastWhisperedAt = this.get("topic.last_whispered_at");
      const lastRespondedAt = this.get("topic.last_responded_at");
      if (lastWhisperedAt && (!lastRespondedAt || moment(lastWhisperedAt).isAfter(moment(lastRespondedAt)))) {
        return (0, _iconLibrary.iconHTML)("far-eye-slash", {
          translatedTitle: `Whispered ${moment(lastWhisperedAt).fromNow()}`
        });
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "whisperIconHTML", [(0, _object.computed)("sloStatus", "topic.last_whispered_at", "topic.last_responded_at")]))();
  }
  _exports.default = WhisperIcon;
});