define("discourse/plugins/discourse-customer-flair-plugin/initializers/add-customer-site-to-post", ["exports", "discourse/lib/plugin-api", "discourse/widgets/render-glimmer", "@ember/template-factory"], function (_exports, _pluginApi, _renderGlimmer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "add-customer-site-to-post",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => {
        const currentUser = api.getCurrentUser();
        if (!currentUser?.staff) {
          return;
        }
        api.includePostAttributes("customer_flair_customer");
        const mobileView = api.container.lookup("service:site").mobileView;
        const loc = mobileView ? "before" : "after";
        api.decorateWidget(`poster-name:${loc}`, dec => {
          const customers = dec.attrs.customer_flair_customer;
          if (!customers || customers.length === 0) {
            return;
          }
          return dec.widget.attach("customer-flair", {
            customers,
            mobileView
          });
        });
        (0, _renderGlimmer.registerWidgetShim)("customer-flair", "div.customer-flair-wrapper", (0, _templateFactory.createTemplateFactory)(
        /*
          <CustomerFlairTooltip @customers={{@data.customers}} @mobileView={{@data.mobileView}} />
        */
        {
          "id": "v3lccz04",
          "block": "[[[8,[39,0],null,[[\"@customers\",\"@mobileView\"],[[30,1,[\"customers\"]],[30,1,[\"mobileView\"]]]],null]],[\"@data\"],false,[\"customer-flair-tooltip\"]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-customer-flair-plugin/initializers/add-customer-site-to-post.js",
          "isStrictMode": false
        }));
      });
    }
  };
});